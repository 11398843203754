import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
            ],
        },
        populate: ['icon', 'marker', 'accessibleLocations'],
        pagination: {
            page: 1,
            pageSize: 5,
        },
    };
};

export default {
    namespaced: true,
    state: {
        entitiesCategories: [],
        query: initialQuery(),
        isDeleteEntityCategory: false,
    },
    getters: {
        getEntityCategoryList(state) {
            return state.entitiesCategories;
        },
        getEntityCategoryQuery(state) {
            return state.query;
        },
        getEntityCategory(state) {
            return state.entityCategory;
        },
        getIsDeleteEntity(state) {
            return state.isDeleteEntityCategory;
        },
    },
    mutations: {
        setEntityCategoryList(state, entitiesCategories) {
            state.entitiesCategories = entitiesCategories;
        },
        setEntityCategoryQuery(state, query) {
            state.query = query;
        },
        resetEntityCategoryQuery(state) {
            state.query = initialQuery();
        },
        setEntityCategory(state, entityCategoryData) {
            state.entityCategoryData = entityCategoryData;
        },
        setEntityCategorySearch(state, search) {
            state.query.filters.$or[0].name.$containsi = search;
        },
        setIsDeleteEntityCategory(state, bol) {
            state.isDeleteEntityCategory = bol;
        },
    },
    actions: {
        fetchEntityCategoryList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/accessible-location-categories?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchEntityCategory(ctx, id, query = {}) {
            query.populate = ['icon', 'marker', 'accessibleLocations'];

            return new Promise((resolve, reject) => {
                axios
                    .get(`/accessible-location-categories/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateEntityCategory(ctx, data) {
            let query = qs.stringify({
                populate: ['icon', 'marker', 'accessibleLocations'],
            });
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                _data.pictures = _.get(_data, 'pictures[0].id') ? [_.get(_data, 'pictures[0].id')] : [];
                axios
                    .put(`/accessible-location-categories/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addEntityCategory(ctx, entityCategoryData) {
            entityCategoryData = _.assign({}, entityCategoryData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/accessible-location-categories', { data: entityCategoryData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteEntityCategory(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/accessible-location-categories/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyEntitiesCategories(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/accessible-location-categories/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
