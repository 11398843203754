<template>
    <router-view></router-view>
</template>
<script>
    import entityCategoryStoreModule from './entityCategoryStoreModule.js';
    import store from '@/store';
    import { onUnmounted } from '@vue/composition-api';

    export default {
        setup() {
            const ENTITY_CATEGORY_APP_STORE_MODULE_NAME = 'app-entity-category';

            // Register module
            if (!store.hasModule(ENTITY_CATEGORY_APP_STORE_MODULE_NAME))
                store.registerModule(ENTITY_CATEGORY_APP_STORE_MODULE_NAME, entityCategoryStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(ENTITY_CATEGORY_APP_STORE_MODULE_NAME))
                    store.unregisterModule(ENTITY_CATEGORY_APP_STORE_MODULE_NAME);
            });
        },
    };
</script>
